import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { MAX_TABLET_WIDTH } from 'constants/sizes';

import {
  FacebookIcon as UIFacebookIcon,
  LinkedinIcon as UILinkedinIcon,
  TwitterIcon as UITwitterIcon,
  ShareIcon as UIShareIcon,
  CheckCircle as UICheckCircle,
} from '../Icons';

import { ButtonStyle } from '../Button/styled';

const IconStyle = css`
  fill: #000;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    fill: #fff;
  }

  @media (pointer: coarse) {
    fill: #fff;
  }
`;

export const FacebookIcon = styled(UIFacebookIcon)`
  ${IconStyle}
`;

export const LinkedinIcon = styled(UILinkedinIcon)`
  ${IconStyle}
`;

export const TwitterIcon = styled(UITwitterIcon)`
  ${IconStyle}
`;

export const ShareIcon = styled(UIShareIcon)`
  fill: none;

  path {
    fill: #fff;
  }
`;
export const CheckIcon = styled(UICheckCircle)`
  fill: none;

  path {
    fill: #fff;
  }
`;

export const ClipboardIconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 3em;
  height: 100%;
  border-right: 1px solid #5f5f5f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    border-color: #ccc;
  }

  @media (pointer: coarse) {
    border-color: #ccc;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 1.5em;
  min-width: 1em;
  height: 1.5em;
  min-height: 1em;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 0;
  transform: scale(0.1);
  transition: all 0.4s ease;
  z-index: 0;
  cursor: pointer;

  ::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1;
    transition: all 0.2s ease;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      background: #000;
    }

    @media (pointer: coarse) {
      background: #000;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    :hover::before {
      opacity: 0.6;
    }
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    opacity: 1;
    transform: scale(1);
    transition: none;
  }

  @media (pointer: coarse) {
    opacity: 1;
    transform: scale(1);
    transition: none;
  }

  input, input:focus, button, button:focus {
    outline: none;
  }
`;

const WrapperStyle = css`
  width: 100%;
  display: flex;
  transition: 0.4s;
  justify-content: center;
  align-items: center;

  > *:not(:first-of-type) {
    margin-left: 0.5em;
  }
`;

export const ButtonTextWrapper = styled.span`
  ${WrapperStyle}

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.8s ease;
  background: #eaeaea;

  @media (pointer: coarse) {
    display: none;
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    display: none;
  }
`;

export const ShareIconsWrapper = styled.div`
  ${WrapperStyle}

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    justify-content: space-between;

    ::before {
      content: 'Share';
      justify-self: start;
      margin-right: auto;
      font-weight: 400;
      color: var(--black);
    }
  }

  ${ShareIcon} path {
    fill: white;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      fill: #000;
    }

    @media (pointer: coarse) {
      fill: #000;
    }
  }

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    padding-left: 1.8em;
  }

  > *:not(:first-of-type) { margin-left: 14px; }

  > *:nth-last-of-type(1) { transition-delay: 0.1s; }

  > *:nth-last-of-type(2) { transition-delay: 0.2s; }

  > *:nth-last-of-type(3) { transition-delay: 0.3s; }
`;

export const Button = styled.div`
  ${ButtonStyle}
  background: #000;
  overflow: hidden;
  cursor: normal;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    background: #eaeaea;

    :hover {
      background: #eaeaea;
    }
  }

  @media (pointer: coarse) {
    background: #eaeaea;

    :hover {
      background: #eaeaea;
    }
  }

  :hover {
    ${ButtonTextWrapper} {
      transform: translateX(-100%);
    }

    ${IconWrapper} {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
